import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Table, Media , Card , CardHeader, Button } from 'reactstrap';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';

export default function OrderTable({courseId,courseName}) {
    const {data:orderData, isPending}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/get_orders/'+courseId);
    const {postData:archiveOrder, postError,resData:resOrder} = usePost();
    const [selectedMembers, setSelectedMembers] = useState([]);
    const {postData:changeLevel, postError:levelError,resData:levelData} = usePost();
    const exportToExcel = () => {
      const filename = "orders.xlsx";
      const wsData = [["Child Name", "Gender", "Age", 'Weight',"Level", "Parent 1", "Mobile 1", "Mobile 2", "Email", "Allergies/Medications/Spl","Paid"]];
      let totalRevenue = 0;
      orderData && orderData.orders.forEach(order => {
        const price = parseFloat(order.course_price) || 0;
        totalRevenue += price;
          wsData.push([`${order.member_first_name} ${order.member_last_name}`,
          order.member_gender,
          order.member_age,
          order.member_riders_weight,
          order.level_name || 'N/A',
          `${order.parent_first_name} ${order.parent_last_name}`,
          order.parent_phone_number,
          order.member_parent2_contact,
          order.parent_email,
          order.member_allergies,
          "₹"+(order.course_price)
        ]);
      });
      wsData.push(["", "", "", "", "", "", "", "", "", "", ""]);
      wsData.push(["Total Revenue", "", "", "", "", "", "", "", "", "", "₹" + totalRevenue.toFixed(2)]);

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `Orders(${courseName})`);
      XLSX.writeFile(wb, filename);
  };

  const handleArchiveOrder = (e, order_id) =>{
      e.preventDefault();
      if (window.confirm("Are you sure you want to delete this order " +order_id+"?")) {
        archiveOrder(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/deleteRiderOrder/" + order_id, {}, "DELETE");
      }

  }

  useEffect(()=>{
    if(resOrder && resOrder.status===200 ){
      NotificationManager.success('Order deleted Successfully!', '', 3000);
        window.location.reload();
    }
    if(postError){
        NotificationManager.error('Could not delete order!', '', 3000);
    }
},[resOrder])

// selecting members for updating the level Id

  // Function to handle individual checkbox change
  const handleSelectMember = (e, member_id) => {
    if (e.target.checked) {
      // console.log(selectedMembers, ' indivisual selected members')
      setSelectedMembers([...selectedMembers, member_id]);
    } else {
      setSelectedMembers(selectedMembers.filter(id => id !== member_id));
      // console.log(selectedMembers, ' indivisual deselected members')
    }
  };

  // Function to handle "select all" checkbox change
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allMemberIds = orderData.orders.map(each => each.member_id);
      setSelectedMembers(allMemberIds);
      // console.log(allMemberIds, ' all selected members')
    } else {
      setSelectedMembers([]);
    }
  };

  // Function to check if a member is selected
  const isMemberSelected = (member_id) => {
    return selectedMembers.includes(member_id);
  };


  

  const handleSubmit = () =>{
    const payload = {child_records:selectedMembers };
    changeLevel(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/change_level/',payload,'POST')
}
useEffect(()=>{
    if(levelData && levelData.status === 200){
        setSelectedMembers([])
        NotificationManager.success(levelData.message, 'Success', 3000);
    }
    if(postError){
        NotificationManager.error(levelError, 'Error', 3000);
    }
},[levelData,levelError])

  return (
    <div>
          <NotificationContainer/>
        {isPending && <Loader/>}
         { <Card className="shadow mt-2">
                <CardHeader className="border-0">
                    <div className="row">
                      <div className="col-sm-3">
                        <h3 className="mb-0">All Orders</h3>
                      </div>
                      <div className="col-sm-6 text-right">
                        <h3 className="mb-0"><small style={{fontSize:"14px"}}> Total Boys: {orderData && orderData.total_male_count}, {" "}
                        Total Girls: {orderData && orderData.total_female_count},{" "}</small>
                        Total Revenue: ₹{orderData && parseInt(orderData.total_revenue, 10).toString()}</h3>
                      </div>
                      <div className="col-sm-3  text-right">
                        {selectedMembers && selectedMembers.length>0 && <button onClick={handleSubmit} class="btn btn-primary btn-sm">Upgrade to Next Level</button>}
                        <button onClick={exportToExcel} class="btn btn-primary btn-sm">Export</button>
                      </div>                    
                    </div>
                </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={orderData && orderData.orders.length > 0 && selectedMembers.length === orderData.orders.length}
                    />
                    </th>
                    <th scope="col">Rider Name</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">Parent Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Level</th>
                    <th>School</th>
                    <th>Paid</th>
                    <th scope="col">Delete Order</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData && orderData.orders.map((each)=>(<tr key={each.order_id}>
                    <th className=''>
                      <input
                        type="checkbox"
                        checked={isMemberSelected(each.member_id)}
                        onChange={(e) => handleSelectMember(e, each.member_id)}
                      />
                    </th>
                    <th scope="row">
                      <Media className="align-items-center">
                        <Media>
                          
                          <Link to={`/admin/rider-details/${each.member_id}`}>
                            <span className="mb-0 text-sm">
                            {each.member_first_name} {each.member_last_name}
                            </span>
                          </Link>

                        </Media>
                      </Media>
                    </th>
                    <td>{each.member_gender}</td>
                    <td>{each.member_age}</td>
                    <td>{each.parent_first_name} {each.parent_last_name}</td>
                    <td>{each.parent_phone_number}</td>
                    <td>
                    {each.parent_email}
                    </td>
                    {each.level_name ? (<td>{each.level_name}</td>) :  (<td>N/A</td>) }
                    <td>{each.member_school_name}</td>
                    <td>₹{each.course_price}</td>
                    <td className='text-center'><Button onClick={(e)=> handleArchiveOrder(e,each.order_id)} className='p-0 shadow-none '><i class="fa-solid fa-trash text-danger"></i></Button></td>
                  
                  </tr>))}
                  {/* {memberData && memberData.length===0 && <tr className="text-center">
                    <div className="text-center py-3">
                      <h3> No Records Found.</h3>
                    </div>
                  </tr>} */}
                </tbody>
              </Table>
              </Card>
}    </div>
  )
}

