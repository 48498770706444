import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Container,
  Col,
  Button,
} from "reactstrap";
import "./Tickets.css"
import CampList from './components/CampList';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
export default function SpectatorIndex() {
  
  // useEffect(() => {
  //   if (window.location.pathname === "/spectator/index") {
  //     const botElement = document.createElement("ai-assist-bot");
  //     document.head.appendChild(botElement);

  //     const link = document.createElement("link");
  //     link.rel = "stylesheet";
  //     link.href = "https://cdn.jsdelivr.net/gh/Open-infotech/AiAssistJsLibForWeb@feature/version2.0/src/v1/19_prod/styles.css"; // Replace with actual URL
  //     document.head.appendChild(link);

  //     // Add <script>
  //     const script = document.createElement("script");
  //     script.id ="helper"
  //     script.src = "https://cdn.jsdelivr.net/gh/Open-infotech/AiAssistJsLibForWeb@feature/version2.0/src/v1/19_prod/bundle.js?key=6c7be466728d6cf2c28be677d4b7c34f"; // Replace with actual URL
  //     // script.async = true;
  //     document.head.appendChild(script);
  //   }
  // }, []);
  return (
    <div>
      
       <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "650px",
            backgroundImage:
                "url(" + require("../../assets/img/spectators/home-banner-1.png") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            }}
        >
        {/* Mask */}
        <span className="mask opacity-3 bg-gradient-default-1" />
        {/* Header container */}
        <Container fluid>
          <Row className='text-center'>
            <Col lg="12" md="12">
              <div style={{alignItems: "center",marginTop:"-18px"}}>
                  <h1 className="text-white mb-0 home__h1" style={{ alignItems: "center"}}>
                  where camp
                  </h1>
                  <h1 className="text-white mb-0 home__h1" style={{ alignItems: "center",marginTop:"-14px"}}>
                  becomes home
                  </h1>
                  <div className='mt-0'>
                    <Link to="/auth/login"><Button className='px-5'>Login/Register</Button></Link>
                  </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="my-5 text-center container" style={{color:"#414042"}}>
      For over 25 years, Japalouppe has provided kids with the best horse riding camps and the perfect home-away-from-home experience. 
      Nestled in the countryside of Talegaon, Japalouppe is within easy driving distance from Pune and Mumbai. Along with a love for horse riding, 
      our campers develop important life skills such as responsibility, confidence, and independence.
      </div>
      <CampList/>
      <div className="text-center about-jap">
        <div className='container py-6 text-white'>
          <h3 className='off-white'>“Japalouppe is where many kids first discover the joy of riding. Campers learn about 
          these majestic animals and gain incredible skills as they create lasting friendships 
          with both humans and horses alike. There’s something magical about connecting with 
          a beautiful horse and learning new things in the process. Horse Riding teaches kids 
          confidence, self-discipline, and respect.”</h3>
          <p className='off-white'>ROHAN MORE, MD JAPALOUPPE</p>
        </div>
      </div>
      <div>
        <iframe width="1519" height="700" src="https://www.youtube.com/embed/1M9UlK6GcG0?si=fi8UUwrA_kEWE4mV&amp;start=25" title="Horse Riding Camps at Japalouppe" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> 
      </div>
      <div>
        <Gallery/>
      </div>
      <div className='mt-5'>
        <Testimonials/>
      </div>
    </div>
  );
}
