import React, { useState } from 'react';
import Slider from 'react-slick';
import './SkillBadge.css'; 
import badge from "../../../assets/img/theme/badge.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFetch } from 'hooks/useFetch';
import { useEffect } from 'react';

export default function SkillBadges({ selectedId}) {
  const {data:badgeData,isPending:badgePending}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getAllBadges/");
  const {data:memberData} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMember/'+selectedId);
  const [badgeRide, setBadgeRide] = useState('');
  useEffect(() => {
    if(memberData){
      setBadgeRide(memberData?memberData.memberDetails.riding_badge:0);
    }
  }, [])
  



  const cardsPerPage = 4; // Number of cards to display per page

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows:true,
    slidesToShow: cardsPerPage,
    slidesToScroll: cardsPerPage,
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint to your desired screen size
        settings: {
          slidesToShow: 1, // Show only 1 card on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Adjust this breakpoint if needed
        settings: {
          slidesToShow: 2, // Show the original number of cards on tablets
          slidesToScroll: 2,
        },
      },
    ],
  };
  

  return (
    <div className="container pt-3">
      <h2>Skill Badges</h2>
      <div className="card-slider-container skillbadges position-relative">
        <Slider {...settings} style={{ margin: "20px" }}>

        { badgeData && 
          badgeData.Message.map((badge, index) => (
            <div key={badge.badge_id}>
              <div className={`card text-center ${index < badgeRide ? 'color-slide' : 'bw-slide'}`} style={{ margin: "20px" }}>
                <div className='text-right mt--3 icon-container'>
                        {
                          index< badgeRide ? 
                            <i className="ni ni-check-bold avatar avatar-sm rounded-circle card-icon"></i>
                          :
                            <div className='avatar avatar-sm rounded-circle lock'> <FontAwesomeIcon icon={faLock} className="locked-icon" /></div>

                        }
                    </div>
                <img src={`${process.env.REACT_APP_DJANGO_SERVER_URL}/api/media/${badge.badge_photo}`} alt={badge.badge_name} />
                <strong className="py-2">{badge.badge_name}</strong>
                </div>
            </div>
          ))
        }
        </Slider>
      </div>
    </div>
  );
}
