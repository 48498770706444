import Loader from 'components/Loader';
import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import { Table, Media , Card , CardHeader, Button } from 'reactstrap';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Switch from "react-switch";
import { useAuthContext } from 'hooks/useAuthContext';
export default function WaitlistTable({courseId,courseName,courseOpenToAll}) {
    const {data:orderData,isPending}=useFetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/get_waitlist/'+courseId);
    const {postData,resData,isPending:postPending}=usePost();
    const {postData:postToggleChange,resData:toggleChangeRes}=usePost();
    const {postData:archiveWaitlistOrder, postError,resData:resWaitlistOrder} = usePost();
    const {postData:sendEmail,resData:emailRes,isPending:emailPending} = usePost();
    const [selectEmailId,setSelectedId]=useState(null);
    const [openToAll,setOpentoAll]=useState(courseOpenToAll);
    const {user} = useAuthContext();
    const [parentEmail,setParentEmail] = useState(null);
    const [parentFName,setParentFName] = useState(null);
    const [memberFirstName,setMemberFirstName] = useState(null);
    const handleSendEmail=(waitlistId,email,parent_first_name)=>{
        setSelectedId(waitlistId);
        postData(process.env.REACT_APP_NODE_SERVER_URL+'/send-email',{
                to: email,
                subject: `Seats Available for ${courseName} - Act Now!`,
                text: `
                Dear ${parent_first_name},
                
                I am delighted to inform you that seats for the course you've been interested in are now available. This is a limited-time opportunity, and I highly recommend securing your spot at your earliest convenience.
                
                Don't miss out on this chance. Hurry up and book your seat today!
                
                Best regards,
                Japalouppe`
              
        },'post')
    }
    
    useEffect(()=>{
        if(resData && resData.status===200){
            setSelectedId(null)
            alert("Email sent successfully!");
        }
    },[resData])
    const handleDBChangeForToggle=()=>{
      postToggleChange(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/toggle_open_for_all/'+courseId,{
        open_for_all: openToAll
      },"PUT")
    }
    const handleToggle=()=>{
      setOpentoAll(!openToAll)
      
    }
    useEffect(()=>{
      handleDBChangeForToggle();
    },[openToAll])


    const handleArchiveWaitlisttedOrder = (e, waitlist_id,parent_email,parent_first_name,member_first_name) =>{
      e.preventDefault();
      if (window.confirm("Are you sure you want to delete this waitlisted Rider?")) {
        setParentEmail(parent_email);
        setParentFName(parent_first_name);
        setMemberFirstName(member_first_name);
        archiveWaitlistOrder(process.env.REACT_APP_DJANGO_SERVER_URL + "/api/deleteWaitlistedRider/" + waitlist_id, {}, "DELETE");
      }

  }
  const handleDeleteCartEmail = () =>{
    const payload = {
      to: parentEmail,
      subject: `Removed from waitlist successfully!`,
      text: `
          Dear ${parentFName},
          ${memberFirstName}'s entry from waitlist for ${courseName} has been deleted successfully.
          Best regards,
          Japalouppe`
    }
    sendEmail(process.env.REACT_APP_NODE_SERVER_URL+'/send-email',payload,"POST")
  }
  
  useEffect(()=>{
    if(emailPending){
      NotificationManager.success('Waitlisted rider deleted Successfully!', 'Sending email...', 3000);
    }
  },[emailPending])

  useEffect(()=>{
    if(emailRes && emailRes.status===200){
        window.location.reload();
    }
  },[emailRes])
  useEffect(()=>{
    if(resWaitlistOrder && resWaitlistOrder.status===200 ){
      handleDeleteCartEmail();
    }
    if(postError){
        NotificationManager.error('Could not delete waitlisted rider!', '', 3000);
    }
},[resWaitlistOrder])

  return (
    <div>
        <NotificationContainer/>
        {isPending && <Loader/>}
          <Card className="shadow mt-2">
          <CardHeader className="border-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 className="mb-0">All Waitlist</h3>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='mb-0 h3' style={{ marginRight: '10px' }}>Open To All</span>
                    <Switch onChange={handleToggle} checked={openToAll} />
                </label>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Rider Name</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">Parent Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Level</th>
                    <th>School</th>
                    <th scope="col">Action</th>
                    <th scope="col">Delete Rider</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData && orderData.waitlist.map((each)=>(<tr key={each.waitlist_id}>
                    <th scope="row">
                      <Media className="align-items-center">
                        <Media>
                          <span className="mb-0 text-sm">
                            {each.member_first_name} {each.member_last_name}
                          </span>
                        </Media>
                      </Media>
                    </th>
                    <td>{each.member_gender}</td>
                    <td>{each.member_age}</td>
                    <td>{each.parent_first_name} {each.parent_last_name}</td>
                    <td>{each.parent_phone_number}</td>
                    <td>
                    {each.parent_email}
                    </td>
                    {each.level_name ? (<td>{each.level_name}</td>) :  (<td>N/A</td>) }
                    <td>{each.member_school_name}</td>
                  <td>
                  {selectEmailId!==each.waitlist_id && <Button  color="primary" size="sm" onClick={()=>handleSendEmail(each.waitlist_id,each.parent_email,each.parent_first_name)}>Send Email</Button>}
                  {postPending && selectEmailId===each.waitlist_id && <Button  color="primary" size="sm" disabled>Sending...</Button>}
                  </td>
                  <td className='text-center'><Button onClick={(e)=> handleArchiveWaitlisttedOrder(e,each.waitlist_id,each.parent_email,each.parent_first_name,each.member_first_name)} className='p-0 shadow-none '><i class="fa-solid fa-trash text-danger"></i></Button></td>
                  </tr>))}
                  {/* {memberData && memberData.length===0 && <tr className="text-center">
                    <div className="text-center py-3">
                      <h3> No Records Found.</h3>
                    </div>
                  </tr>} */}
                </tbody>
              </Table>
              </Card>
    </div>
  )
}
