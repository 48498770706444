// reactstrap components
import { useAuthContext } from "hooks/useAuthContext";
import { useFetch } from "hooks/useFetch";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'slick-carousel/slick/slick-theme.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
  } from "reactstrap";
  import kid3 from "../../assets/img/theme/kid-3.jpg";
import { useEffect, useState } from "react";
import { usePost } from "hooks/usePost";
  // core components  
  const ParentProfile = () => {
    
    const {user, dispatch}=useAuthContext();
    const {data:parent_details}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/parent_details/"+user.user_id);
    const {data:children}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getChildren/'+user.user_id);
    const {postData, resData, postError, isPending} = usePost();
    const {postData:otpData, resData:otpRes, postError:otpError, isPending:otpLoad} = usePost();
    const {postData:verifyData, resData:verifyRes, postError:verifyError, isPending:verifyLoad} = usePost();
    const {postData:logoutData, resData:logoutRes, postError:logoutError} = usePost();


    const [isEdit,setIsEdit]=useState(false);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows:false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 768, // Adjust this breakpoint to your desired screen size
            settings: {
              slidesToShow: 1, // Show only 1 card on mobile
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992, // Adjust this breakpoint if needed
            settings: {
              slidesToShow: 2, // Show the original number of cards on tablets
              slidesToScroll: 2,
            },
          },
        ],
      };

      const [firstName, setFirstName] = useState(null);
      const [lastName, setLastName] = useState(null);
      const [email, setEmail] = useState(null);
      const [phoneNumber, setPhoneNumber] = useState(null);
      const [otp, setOtp] = useState(null);
      const [isOtpSend, setIsOtpSend] = useState(false);

      useEffect(() => {
        if(parent_details){
          setEmail(parent_details.email)
          setPhoneNumber(parent_details.phone_number)
          setFirstName(parent_details.first_name)
          setLastName(parent_details.last_name)
        }
      }, [parent_details])
      


      // if the contact has been changed then only otp is sent
    const handleSendOtp = (e) =>{
      const formData = {
        phone_number: String(phoneNumber),
        first_name: firstName!==null? firstName:parent_details.first_name,
        last_name: lastName!==null? lastName:parent_details.last_name
      }
      // console.log(formData,' for otp')
      otpData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/sendOTP/",formData,"post");
    }

    useEffect(() => {
      if (otpRes && otpRes.status ===200){
        NotificationManager.success(otpRes.Message, '', 1500);
        setIsOtpSend(true)
        // handleVerifyOtp();
      }
      if(otpError){
        NotificationManager.error('Error while sending otp', '', 1500);
      }
    }, [otpRes, otpError]);




// once otp has been sent here verfication code for that

    const handleVerifyOtp = (e) => {
      const formData = {
        phone_number:String(phoneNumber),
        otp:otp,
        user_id:parent_details.user_id
      }
      // console.log(formData,' for verfication')
      verifyData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/verifyOtp/",formData,"post");    
    }

    useEffect(() => {
      if(verifyRes && verifyRes.status===200){
        NotificationManager.success(verifyRes.Message, '', 1500);
        setIsOtpSend(false)
        handleParentDataSubmit()
      }
      if(verifyError){
        NotificationManager.error(verifyError, '', 1500);
      }
    }, [verifyRes,verifyError ]);





// if contact has been updated then the account gets logged out
const handleLogout = (e) =>{
  logoutData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/logout/",{},"post")
}
useEffect(() => {
  if(logoutRes && logoutRes.status===200){
    NotificationManager.info('This session will be logged out, please login with the new updated number', '', 2500);
    setInterval(()=> {
      localStorage.removeItem('user');
      dispatch({type:'LOGOUT'});
    },2000)
  }
  if(logoutError){
    NotificationManager.error('Error while logging out', '', 3500);
  }
}, [logoutRes, logoutError]);





// here is parent details update function this is the MAIN function
      const handleCheckPhoneNumber = (e) =>{
        e.preventDefault();
        const phone_number= String(phoneNumber);
        if(phone_number==='' || phone_number===null){
          NotificationManager.error('Please enter a valid number', '', 2000);
          return;
        }
        if(phone_number !==parent_details.phone_number){
            handleSendOtp()
        }else if(phone_number===parent_details.phone_number){
          handleParentDataSubmit()
        }else{
          NotificationManager.error('Error! At Number verification', '', 2000);
        }
      }

      const handleParentDataSubmit = (e) =>{
        // const phone_number= "+91" + String(phoneNumber);
        const formData = {
          parent_id : parent_details.user_id,
          phone_number: phoneNumber!==null?phoneNumber:parent_details.phone_number,
          first_name:firstName!==null?firstName:parent_details.first_name,
          last_name:lastName!==null?lastName:parent_details.last_name,
          email:email!==null?email:parent_details.email,
          username:parent_details.username
        }
        // console.log('here is the formdata',formData)
        // return;
        postData(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/updateParent/"+Number(parent_details.user_id),formData,"PUT");    

        // setIsEdit(false)
      }
      useEffect(() => {
        if(resData && resData.status===200 && phoneNumber===parent_details.phone_number){
          const userData = JSON.parse(localStorage.getItem('user'))
          userData.first_name = firstName
          userData.last_name = lastName
          userData.email = email
          userData.phone_number = phoneNumber
          userData.username = firstName
          localStorage.setItem('user', JSON.stringify(userData));
          NotificationManager.success(resData.Message, '', 2500);
          window.location.href = '/parent/index'
        }
        if(resData && resData.status===200 && phoneNumber!==parent_details.phone_number){
          NotificationManager.success(resData.Message, '', 2500);
          handleLogout()
        }
        if(postError){
          NotificationManager.error(postError.Error, '', 3500);
        }
      }, [resData, postError]);
      
      

    return (
      <>
        <NotificationContainer />
        {/* Header */}
        {parent_details && <div>
        <div>
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
            minHeight: "400px",
            backgroundImage:
                "url(" + require("../../assets/img/theme/counter-bg.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            }}
        >
        {/* Mask */}
        <span className="mask opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="text-white d-flex" style={{"fontSize":"46px",alignItems: "center"}}>Welcome, {user.first_name}!
              </h1>
              {/* <p className="text-white ">
                Let's get ready for an exciting journey filled with fun and learning for your little ones!
              </p> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col xl="2"></Col>
            <Col className="order-xl-1" xl="8">
            <Form onSubmit={handleCheckPhoneNumber}>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">My account</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {!isEdit &&<Button
                          color="primary"
                          onClick={() => setIsEdit(true)}
                          size="sm"
                        >
                          Edit
                        </Button>}
                        {isEdit && <Button
                          color="primary"
                          type='submit'
                          size="sm"
                        >
                          Save
                        </Button>}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                      <h6 className="heading-small text-muted mb-4">
                        User information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-phone"
                              >
                                {!isOtpSend?"Phone Number":"Enter OTP"}
                              </label>
                              <div className="d-flex align-items-center">
                                {!isOtpSend && <Input style={{'width':'65%'}}
                                  className="form-control-alternative"
                                  value={phoneNumber}
                                  id="input-phone"
                                  placeholder="Phone Number"
                                  onChange={(e)=>setPhoneNumber(e.target.value)}
                                  type="text"
                                  readOnly={!isEdit}
                                />}

                                {isOtpSend && <Input style={{'width':'65%'}}
                                  className="form-control-alternative"
                                  defaultValue=''
                                  id="input-phone"
                                  placeholder="Enter OTP Here"
                                  onChange={(e)=>setOtp(e.target.value)}
                                  type="text"
                                  readOnly={!isEdit}
                                />}

                                { !isOtpSend && <Button className="btn-md btn-primary text-white ml-2" disabled={!isEdit}>Send OTP</Button>}
                                { isOtpSend && <Button className="btn-md btn-primary text-white ml-2" disabled={!isEdit} onClick={handleVerifyOtp}>Verify</Button>}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={email}
                                id="input-email"
                                placeholder="jesse@example.com"
                                onChange={(e)=> setEmail(e.target.value)}
                                type="email"
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                First name
                              </label>
                              <Input
                                className="form-control-alternative text-capitalize"
                                value={firstName}
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                onChange={(e)=> setFirstName(e.target.value)}
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Last name
                              </label>
                              <Input
                                className="form-control-alternative text-capitalize"
                                value={lastName}
                                id="input-last-name"
                                placeholder="Last name"
                                type="text"
                                onChange={(e)=> setLastName(e.target.value)}
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      {/* <h6 className="heading-small text-muted mb-4">
                        Contact information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Address
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                id="input-address"
                                placeholder="Home Address"
                                type="text"
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                City
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="New York"
                                id="input-city"
                                placeholder="City"
                                type="text"
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Country
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="United States"
                                id="input-country"
                                placeholder="Country"
                                type="text"
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Postal code
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-postal-code"
                                placeholder="Postal code"
                                type="number"
                                readOnly={!isEdit}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> */}
                      <hr className="my-4" />
                      {/* Description */}
                      {/* <h6 className="heading-small text-muted mb-4">Members</h6> */}
                      <Slider {...settings}>
                      {children &&
                          children.children.map((each, index) => (
                          <div key={index} className="slider-item">
                              <div className="slider-content">
                              <img
                                  className="profile-avatar"
                                  src={kid3}
                                  alt=""
                              />
                              <div>{each.first_name}</div>
                              </div>
                          </div>
                          ))}
                      </Slider>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        </div>}
      </>
    );
  };
  
  export default ParentProfile;
  