import { useAuthContext } from 'hooks/useAuthContext';
import { usePost } from 'hooks/usePost'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
Button,
Card,
CardHeader,
CardBody,
Container,
Row,
Col,
} from "reactstrap";
import { useFetch } from 'hooks/useFetch';
import Loader from 'components/Loader';
export default function CourseDetails() {
const {courseId,memberId}=useParams();
const {user}=useAuthContext();
const {data:courseData}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/getCourseDetailsAndNextLevelPrice/"+courseId+"/"+memberId);
const {data:isPresentInCart}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+`/api/presentInCart/${memberId}/${user.user_id}/${courseId}`);
const {data:isCourseBought} = useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+`/api/isCourseBought/${memberId}/${courseId}`);
const {data:childData}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/getMember/'+Number(memberId))
const {data:waitlist}=useFetch(process.env.REACT_APP_DJANGO_SERVER_URL+'/api/checkWaitlist/'+memberId+'/'+courseId)
const [isCapacityFullData, setisCapacityFullData] = useState(null);
const {postData,resData,isPending}=usePost();
const {postData:postToWaitList,resData:waitlistRes}=usePost();
const [isLoading,setIsLoading]=useState(false);
const navigate=useNavigate();


const fetchChildData = async (capacity) => {
   setIsLoading(true);
   try {
     const response = await fetch(process.env.REACT_APP_DJANGO_SERVER_URL+`/api/checkCapacity/${courseId}/${courseData.course_details.child_next_level }/${capacity}/${courseData.course_details.open_for_all ? '1':'0'}/${memberId}/${childData.memberDetails.gender}`);
     console.log(process.env.REACT_APP_DJANGO_SERVER_URL+`/api/checkCapacity/${courseId}/${courseData.course_details.child_next_level }/${capacity}/${courseData.course_details.open_for_all ? '1':'0'}/${memberId}/${childData.memberDetails.gender}`);
     const data = await response.json();

     setisCapacityFullData(data);
     console.log(data)
     setIsLoading(false);
   } catch (error) {
     console.error('Error fetching child data:', error);
   }
 };
useEffect(() => {
   if(courseData && childData){
     if(childData.memberDetails.gender==="male"){
         fetchChildData(courseData.course_details.boy_seats);
     }
     else if(childData.memberDetails.gender==="female"){
         fetchChildData(courseData.course_details.girl_seats);
     }  
     }
}, [courseData,childData])

const handleAddToWaitlist = () =>{
   postToWaitList(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/add_to_waitlist/",{
      member_id: memberId,
      parent_id: user.user_id,
      course_id: courseId,
      for_level: courseData.course_details.child_next_level,
      gender: childData.memberDetails.gender
      },"POST")
}
useEffect(()=>{
   if(waitlistRes && waitlistRes.status===200){
      NotificationManager.success('Waitlist Joinned successfully!', '', 3000);
      setTimeout(() => {
         navigate('/parent/index')
      }, 3000);
   }
},[waitlistRes])
const handleAddtoCart=()=>{
   postData(process.env.REACT_APP_NODE_SERVER_URL+"/api/add_to_cart",{
   member_id: memberId,
   parent_id: user.user_id,
   course_id: courseId,
   next_level_id: courseData.course_details.child_next_level,
   course_price: courseData.course_details.course_price
   },"POST")
}
useEffect(()=>{
   if(resData && resData.status===200){
   NotificationManager.success('Added to cart successfully!', '', 3000);
   setTimeout(() => {
      window.location.reload();
   }, 3000);   
   }
},[resData])
return (
    <>
    {isLoading && <Loader/>}
{ isPresentInCart && courseData && isCapacityFullData  && isCourseBought && waitlist && <div className=''>
   <NotificationContainer/>
   <div
   className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
   style={{
   minHeight: "400px",
   backgroundImage:
   "url(" + require("../assets/img/theme/counter-bg.jpg") + ")",
   backgroundSize: "cover",
   backgroundPosition: "center center",
   }}
   >
   {/* Mask */}
   <span className="mask  opacity-8" />
   {/* Header container */}
   <Container className="d-flex align-items-center" fluid>
      <Row>
         <Col lg="12" md="10">
         <h1 className="display-2 text-white">{courseData.course_details.course_name}</h1>
         {/* <p className="text-white mt-0 mb-5">
         </p> */}
         </Col>
      </Row>
   </Container>
</div>
 <div className="container">
   <Row className='my-5'>
      <Container fluid>
         <Row className='mt--9'>
            <Col xl="7">
            <Card className="bg-secondary shadow">
               <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                     <Col xs="8">
                     <h3 className="mb-0">About this Course</h3>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
                  {courseData.course_details.description}
               </CardBody>
            </Card>
            </Col>
            <Col xl="5">
            <Card className="bg-secondary shadow">
               <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                     <Col xs="8">
                     <h3 className="mb-0">Course Details</h3>
                     </Col>
                  </Row>
               </CardHeader>
               <CardBody>
               <div>
                  <p><small>Course Price</small></p>
                  <h1 className='mt--3'>₹ {courseData.course_details.course_price}</h1>
                    <p><small>Certification</small></p>
                    <p className='mt--3'><i className='ni ni-paper-diploma'></i><span style={{fontWeight:"500"}}>&nbsp;&nbsp;&nbsp; {courseData.course_details.certification === 1? "Yes" : "No"}</span></p>   
                </div>
                <div>
                    <p> <small>Start Date</small></p>
                    <p className='mt--3'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500"}}>&nbsp;&nbsp;&nbsp;{new Date(courseData.course_details.course_from_date).toLocaleDateString(undefined, { month: 'long', day: 'numeric', year:'numeric' })}</span></p>   
                </div>
                <div>
                    <p> <small>End Date</small></p>
                    <p className='mt--3'><i className='ni ni-calendar-grid-58'></i><span style={{fontWeight:"500"}}>&nbsp;&nbsp;&nbsp;{new Date(courseData.course_details.course_to_date).toLocaleDateString(undefined, { month: 'long', day: 'numeric', year:'numeric' })}</span></p>   
                </div>
                {/* <div>
                    <p> <small>Total Capacity</small></p>
                    <p className='mt--3'><i className='ni ni-single-02'></i>
                    <span style={{fontWeight:"500"}}>&nbsp;&nbsp;&nbsp;Girls {courseData.course_details.girls_capacity}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className='ni ni-single-02'></i><span style={{fontWeight:"500"}}>&nbsp;&nbsp;&nbsp;Boys: {courseData.course_details.boys_capacity}</span>
                    </p>   
                </div> */}
                <div>
                    {!isPending && !isPresentInCart.isRecordPresent && !isCapacityFullData.isCapacityFull && !isCourseBought.isCourseBought && courseData.course_details.open_for_all &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary" onClick={handleAddtoCart}>Book This Camp</Button></div>
                    }
                     {!isPending && !isPresentInCart.isRecordPresent && !isCapacityFullData.isCapacityFull && !isCourseBought.isCourseBought && waitlist.isPresentInWaitlist && !courseData.course_details.open_for_all &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary" onClick={handleAddtoCart}>Book This Camp</Button></div>
                    }
                    {/* {!isPending && !isPresentInCart.isRecordPresent && !isCapacityFullData.isCapacityFull && !isCourseBought.isCourseBought && courseData.course_details.open_for_all && !waitlist.isPresentInWaitlist &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary" onClick={handleAddtoCart}>Add to cart</Button></div>
                    } */}
                    {isPending  && !isCapacityFullData.isCapacityFull &&
                    <div><Button className='d-flex justify-content-center w-100 disabled'>Adding...</Button></div>
                    }
                    {isPresentInCart.isRecordPresent  && !isCapacityFullData.isCapacityFull &&
                    <div><Link to='/parent/cart'><Button className='d-flex justify-content-center w-100' color="primary" >Go to Cart</Button></Link></div>
                    }
                    { isCapacityFullData.isCapacityFull &&  !waitlist.isPresentInWaitlist && !isCourseBought.isCourseBought && courseData.course_details.open_for_all &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary" onClick={handleAddToWaitlist}>Join Waitlist</Button></div>
                    }
                     { isCapacityFullData.isCapacityFull &&  !waitlist.isPresentInWaitlist && !isCourseBought.isCourseBought && !courseData.course_details.open_for_all &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary" onClick={handleAddToWaitlist}>Join Waitlist</Button></div>
                    }
                    {  !isPresentInCart.isRecordPresent &&  !isCapacityFullData.isCapacityFull &&  !waitlist.isPresentInWaitlist && !isCourseBought.isCourseBought && !courseData.course_details.open_for_all &&
                    <div><Button className='d-flex justify-content-center w-100' color="primary"  onClick={handleAddtoCart}>Book This Camp</Button></div>
                    }
                    
                    { isCapacityFullData.isCapacityFull &&  waitlist.isPresentInWaitlist && !isCourseBought.isCourseBought && 
                    <div><Button className='d-flex justify-content-center w-100' color="primary" disabled>Waitlist Joinned</Button></div>
                    }
                    { isCourseBought.isCourseBought && 
                    <div><Button className='d-flex justify-content-center w-100' color="primary" disabled>Course Bought</Button></div>
                    }
                </div>
               </CardBody>
            </Card>
            </Col>
         </Row>
      </Container>
   </Row>
</div>
</div>}
</>
)
}