import { useFetch } from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import localImage from "../../assets/img/theme/counter-bg.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import "./Cart.css";
import {
  Row,
  Container,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useAuthContext } from "hooks/useAuthContext";
import { usePost } from "hooks/usePost";
import Indemnity from "./components/Indemnity";
import { cashfree } from "cashfree/Util";

export default function Cart() {
  const {postData:fetchCoupon,resData:couponData, postError:couponErr}=usePost();
  const { user } = useAuthContext();
  const [indemnityModal, setIndemnityModal] = useState(false);
  const {postData:archiveCart, resData: archiveCartRes}=usePost();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItemId,setDeleteItemId]=useState(0);
  const [indemnityAgreed,setIndemnityAgreed] = useState(false);
  const [isCouponVerified,setIsCouponVerified] = useState(false);
  const [couponCode , setCouponCode] = useState('')
  const [cartPrice , setCartPrice] = useState(0);

  const [isCoupon,setIsCoupon] = useState(false);
  const [couponAmount, setCouponAmount] = useState(0);

  const [isGiftCard, setIsGiftCard] = useState(false);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  
  const indemnitytoggle = () => {
    setIndemnityModal(!indemnityModal);
  }
  const handleCheckboxChange = () => {
    console.log(!indemnityAgreed)
    setIndemnityAgreed(!indemnityAgreed);
};
  const { data: cartData } = useFetch(
    process.env.REACT_APP_DJANGO_SERVER_URL+"/api/cart/" + user.user_id
  );
  const [cartTotal, setCartTotal] = useState(0);
  const navigate=useNavigate();
  useEffect(() => {
    let count = 0;
    if (cartData) {
      cartData.forEach((each) => {
        count = count + each.course_level_seats.price;
      });
      setCartTotal(count);
      setCartPrice(count); // created another state for price()items
    }
  }, [cartData]);
  const handlePopup=(cartId)=>{
    setDeleteItemId(cartId)
    setIsDeleteModalOpen(true)
  }
  const handleCartDelete=async(cartId)=>{
    try {
        const response = await fetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/delete_cart_items', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartIds: [cartId] }), // Use an array with a single cartId
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        if(data && data.status===200){
            setIsDeleteModalOpen(false);
            NotificationManager.success('Item removed from the cart successfully!', '', 3000);
            setTimeout(()=>{
                window.location.reload();
            },3000)
        }
      } catch (error) {
        console.error('Error archiving cart:', error);
        NotificationManager.error(error.message, '', 3000);
      }
  }
  // const handleOrder=()=>{
  //   const payload = cartData.map((each)=>{
  //       return {
  //           parent_id: each.parent_id,
  //           course_id: each.course,
  //           member_id: each.member,
  //           for_level: each.next_level_id,
  //           gender: each.child_data.gender,
  //           payment_id: 303
  //         }
  //   })
  //   postData(process.env.REACT_APP_NODE_SERVER_URL+"/api/add_orders",payload,"POST");
  // }
  const handleOrder=async()=>{
    let payload={
      "customer_details": {
        "customer_id": String(user.user_id),
        "customer_phone": String(user.phone_number)
      },
      "order_amount": Number(cartTotal),
      "order_currency": "INR"
  }
try{
  const res =await fetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/createAnOrder',{
    method: 'POST',
        headers:{
            'Content-Type':'application/json',
        },
        body: JSON.stringify(payload),
  })
if(!res.ok){
  throw new Error('Something went wrong!')
}
  const data=await res.json();
  handlePayment(data.payment_session_id,data.order_id);
  
}catch(err){
  console.log(err.message)
}
  }
  const handlePayment = (sessionId,orderId) => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: process.env.REACT_APP_SERVER_URL+'/parent/order-success/'+orderId, 
    }
    cashfree.checkout(checkoutOptions).then(function(result){
      if(result.error){
        alert(result.error.message)
      }
      if(result.redirect){
        console.log("Redirection")
      }
    });
  }
const createAPaymentLink=async(data)=>{
  let payload={
  "customer_details": {
    "customer_phone": user.phone_number,
    "customer_email": user.email,
    "customer_name": user.first_name +' '+ user.last_name
  },
  "link_notify": {
    "send_sms": false,
    "send_email": false
  },
  "link_meta": {
  "notify_url": process.env.REACT_APP_SERVER_URL+'/parent/order-success/data.order_id',
  "return_url": process.env.REACT_APP_SERVER_URL+'/parent/order-success/'+data.order_id
  },
  "link_id": data.order_id,
  "link_amount": data.order_amount,
  "link_currency": "INR",
  "link_purpose": "Payment for Japalouppe Camp",
  "link_partial_payments": false
}

try{
const res =await fetch(process.env.REACT_APP_NODE_SERVER_URL+'/api/createPaymentLink',{
  method: 'POST',
      headers:{
          'content-type':'application/json; charset=UTF-8',
      },
      body: JSON.stringify(payload),
})
if(res.ok){
  const data=await res.json();
  window.location.href=data.link_url
  
}else{
  console.error('Request failed with status:', res.status);
  const errorText = await res.text();
  console.error('Error response:', errorText);
}


}catch(err){
console.log(err.message)
}

}

  useEffect(() => {
    if(archiveCartRes && archiveCartRes.status===200){
       navigate('/parent/order-success');
    }
  }, [archiveCartRes])

  
const handleCouponVerification = () => {
  fetchCoupon(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/fetchGiftCard/",
  {
    gift_card:couponCode
  }, "POST")

};

useEffect(()=>{
  if(couponData && couponData.status===200){
    // gift card code implementation
    if(couponData.couponData.is_gift_card && !couponData.couponData.is_gift_card_redeemed){
      setIsCoupon(false)
      setIsGiftCard(true)
      console.log('yes it is active gift card')
      const gift_card_amount = couponData.couponData.gift_card_amount
      setCartTotal(cartTotal - Number(gift_card_amount))
      setGiftCardAmount(gift_card_amount)
      setIsCouponVerified(true);
      NotificationManager.success('Coupon Is Valid!', '', 3000);
      return 
    }

    // Coupon code implementation
    if( !couponData.couponData.is_gift_card && couponData.couponData.is_active && couponData.couponData.is_percent_discount){
      setIsGiftCard(false)
      setIsCoupon(true)
      const coupon_percent_discount = couponData.couponData.coupon_discount
      const coupon_discount_amount = (cartTotal*Number(coupon_percent_discount)/100)
      setCouponAmount(coupon_discount_amount)
      setCartTotal(cartTotal - coupon_discount_amount)
      setIsCouponVerified(true);
      // console.log('yes it is % active coupon ',coupon_discount_amount)
      NotificationManager.success('Coupon Is Valid!', '', 3000);
      return
      
    }
    if( !couponData.couponData.is_gift_card && couponData.couponData.is_active && !couponData.couponData.is_percent_discount){
      setIsGiftCard(false)
      setIsCoupon(true)
      const coupon_discount_amount = couponData.couponData.coupon_discount
      setCouponAmount(coupon_discount_amount)
      setCartTotal(cartTotal - coupon_discount_amount)
      setIsCouponVerified(true);
      // console.log('yes it is amount active coupon')
      NotificationManager.success('Coupon Is Valid!', '', 3000);
      return 
      
    }
    NotificationManager.error('Coupon Is InValid!', '', 3000);
    return

  }
  if(couponErr){
    NotificationManager.error(couponErr, '', 3000);
    setIsCouponVerified(false);
  }
},[couponData, couponErr])


const {postData:checkSeatsAvailibility,resData:checkSeatsAvailibilityData, postError:checkSeatsAvailibilityErr}=usePost();
// check course seats availibility
const checkCourseSeatsAvailibility = () => {
  const payload = cartData.map((each)=>{
    return  {
              "level_id": each.next_level_id,
              "course_id": each.course,
              "member_id": each.member
          }
  })
    checkSeatsAvailibility(process.env.REACT_APP_DJANGO_SERVER_URL+"/api/check_course_level_seats/",
    payload, "POST")
}
useEffect(() => {
  if(checkSeatsAvailibilityData && checkSeatsAvailibilityData.status===200){
    handleCheckSeatsResponse(checkSeatsAvailibilityData.response)
  }

  if(checkSeatsAvailibilityErr && checkSeatsAvailibilityData.status===400){
    NotificationManager.error('Error While course available seats', '', 3000);
  }
}, [checkSeatsAvailibilityData]);



const handleCheckSeatsResponse = (seatAvailabilityResponse) => {
  // Initialize a flag to track whether all seats are available
  let allSeatsAvailable = true;
  let errorMessage = '';

  // Loop through each result from the API response
  seatAvailabilityResponse.forEach((result) => {
    const { member_id, course_id, level_id, status, message,member_name } = result;

    // If the status is "full", set the flag to false and construct the error message
    if (status === 'full') {
      allSeatsAvailable = false; // Set flag to false if any seat is full
      errorMessage += `Please remove Rider ${member_name} from cart as ${message} for his course\n`;
    }
  });

  // If all seats are available, proceed with the next function
  if (allSeatsAvailable) {
    console.log('All seats are available. Proceeding to the next step.');
    proceedWithBooking(); // Call the function to complete the booking process
  } else {
    // If any seat is full, display the error message
    console.error(errorMessage);
    alert(errorMessage); // Show error message to the user (could be a modal or toast)
  }
};


const proceedWithBooking = () => {
  console.log('Proceeding with the booking process...');
  indemnitytoggle()
  // Add your booking logic here (e.g., completing payment, confirming booking)
};


  return (
    <div>
        <NotificationContainer/>
         {/* Modal */}
         <Indemnity isChecked={indemnityAgreed} toggleCheckbox={handleCheckboxChange} indemnityModal={indemnityModal} indemnitytoggle={indemnitytoggle} handleOrder={handleOrder} isCouponVerified={isCouponVerified} couponCode={couponCode} cartTotal={cartTotal}/>
        <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
        <ModalHeader toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>Confirm Delete</ModalHeader>
        <ModalBody>
            Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={()=>handleCartDelete(deleteItemId)}>Yes</Button>
            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>No</Button>
        </ModalFooter>
    </Modal>
        {cartData && cartData.length===0 &&<div className="text-center pt-8">
            <h1>The cart is empty!</h1>
            <Link to="/parent/index">
                <Button color="primary">Explore</Button>
            </Link>  
        </div>}
      {cartData && cartData.length>0 && <div>
        <Row className="pt-8">
          <Container fluid>
            <Row>
              <Col xl="8">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Items</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      {cartData &&
                        cartData.map((each) => (
                          <div className="col-lg-6" key={each.cart_id}>
                            <div className="card mb-4">
                              <div>
                                <span className="badge badge-success bg-success position-absolute top-3 left-3">
                                  <strong className="text-white">For: {each.child_data.first_name}</strong>
                                </span>
                                <span 
                                onClick={()=>handlePopup(each.cart_id)}
                                className="badge badge-white position-absolute rounded-circle top-3 right-3"
                                style={{'cursor':'pointer'}}>
                                <FontAwesomeIcon 
                                style={{ 'cursor':'pointer','color':'#fff', 'fontSize':'14px','padding':'2px'}}  icon={faTrash} />
                                </span>
                                <img
                                  src={localImage}
                                  className="card-img-top w-100 postion-relative"
                                  style={{
                                    height: "180px",
                                    objectFit: "cover",
                                  }}
                                  alt="..."
                                />
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <h3 className="card-text">
                                      {each.course_data.course_name}
                                    </h3>
                                  </div>
                                  <div className="col-sm-4">
                                    <h3 className="card-text">
                                      ₹ {each.course_level_seats.price}
                                    </h3>
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-sm-6">
                                    <div>
                                      <p>
                                        {" "}
                                        <small>Start Date</small>
                                      </p>
                                      <p className="mt--3">
                                        <i className="ni ni-calendar-grid-58"></i>
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "15px",
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;
                                          {new Date(
                                            each.course_data.course_from_date
                                          ).toLocaleDateString(undefined, {
                                            month: "short",
                                            day: "numeric",
                                            year: "2-digit",
                                          })}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div>
                                      <p>
                                        {" "}
                                        <small>End Date</small>
                                      </p>
                                      <p className="mt--3 text-right">
                                        <i className="ni ni-calendar-grid-58"></i>
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "15px",
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;
                                          {new Date(
                                            each.course_data.course_to_date
                                          ).toLocaleDateString(undefined, {
                                            month: "short",
                                            day: "numeric",
                                            year: "2-digit",
                                          })}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <Link
                                  to={`/parent/course-details/${each.course}/${each.member}`}
                                  className="d-flex justify-content-center"
                                >
                                  <Button
                                    color="primary"
                                    className="w-100 primary text-white"
                                  >
                                    View Details
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                       
                    </div>
                  </CardBody>
                  <div className="sticky-foote">
                        <div className="d-flex justify-content-end m-3">
                            {/* <Button
                                color="white"
                                className="primary text-dark"
                                onClick={handleOrder}
                            >
                                Book Camp(s)
                            </Button>  */}
                              {/* {<Button color="primary" onClick={indemnitytoggle}>
                                Book Camp(s)
                              </Button>} */}
                        </div> 
                     </div>
                </Card>
              </Col>
              <Col xl="4">
                <Card className="bg-secondary shadow sticky-card">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Price Details</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  {cartData && (
                    <CardBody>
                      <div className="row mx-1 mb-4">
                        <div class="input-group-alternative input-group p-0">
                          <Input required placeholder="Enter Coupon" type="Text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} className="form-control"></Input>
                          { !isCouponVerified && <button  type="button" className="btn btn-primary"onClick={handleCouponVerification}>Verify</button>}
                          { isCouponVerified && <button disabled  type="button" className="btn btn-primary" >Verify</button>}
                        </div>
                        {isCouponVerified && <small className="text-success">Coupon Applied!</small>}
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          Price ({cartPrice.length} Items)
                        </div>
                        <div className="col-sm-6 text-right">₹ {cartPrice}</div>
                      </div>
                      {isCouponVerified && <div className="row mb-4">
                        <div className="col-sm-6">
                          <small>Coupon Applied</small>
                        </div>
                        <div className="col-sm-6 text-right">
                          { isCoupon && !isGiftCard && <small>₹ - {couponAmount}</small>}
                          { !isCoupon && isGiftCard && <small>₹ - {giftCardAmount}</small>}
                        </div>
                      </div>}
                      <div className="row mt-2">
                        <div className="col-sm-6">
                          <h2>Total</h2>
                        </div>
                        <div className="col-sm-6 text-right">
                          <h2>₹ {Number(cartTotal)}</h2>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <Button color="primary" onClick={checkCourseSeatsAvailibility}>
                          Book Camp(s)
                        </Button>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </div>}
    </div>
  );
}
